import React, { useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
// import { Brand, Button } from "./../atoms";
import { logout } from "../../redux/actions"
// import { Trans } from "@lingui/macro";
import { navigationlinks, fakeModalScreens } from "../../utils/navigationLinks"
import { _matchRouteSlug, isUserLoginSuccessful } from "../../utils/helper"
import { Brand } from "./../atoms"

const RenderMenu = (props) => {
  const currentRoute = props.location.pathname

  if (isUserLoginSuccessful() == false) {
    return ""
  }

  let nav

  nav = props.role !== null ? navigationlinks[props.role] : undefined

  return nav === undefined ? null : (
    <nav className="primary-navigation">
      <ul>
        {nav.map((menu, index) => (
          <MenuItem
            key={index}
            item={menu}
            currentRoute={currentRoute}
            {...props}
          />
        ))}
      </ul>
    </nav>
  )
}

const MenuItem = ({ item, currentRoute, ...props }) => {
  const hasChildren = item.children && item.children.length > 0
  return (
    <li className={`nav-menu-item ${hasChildren ? "has-children" : ""}`}>
      <button
        className={`${currentRoute === item.url ? "active" : ""}`}
        onClick={(e) => {
          if (item.url) {
            props.closeMobileMenu()
            props.history.push(item.url)
          }
        }}
      >
        {item.name}
      </button>
      {hasChildren && (
        <ul className="submenu">
          {item.children.map((child, index) => (
            <MenuItem
              key={index}
              item={child}
              currentRoute={currentRoute}
              {...props}
            />
          ))}
        </ul>
      )}
    </li>
  )
}

const Header = (props) => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  return (
    <React.Fragment>
      {/* {props.token === null && (
        <header>
          <div className="py-10 flex justify-center">
            <Brand />
          </div>
        </header>
      )} */}
      {props.token !== null &&
        !_matchRouteSlug(fakeModalScreens, props.location.pathname) && (
          <header className="relative">
            <button
              className="mobile-menu-toggle cursor-pointer hover:opacity-50 lg:hidden"
              onClick={() => {
                setOpenMobileMenu(!openMobileMenu)
              }}
            >
              <span className="font-bold">MENU</span>
            </button>
            <div
              className={`pt-8 mobile-menu ${
                !openMobileMenu && "hidden"
              } lg:hidden`}
            >
              <div>
                <RenderMenu
                  {...props}
                  closeMobileMenu={() => {
                    setOpenMobileMenu(false)
                  }}
                />
                {/* MOBILE */}
                <div
                  className="text-center pt-10"
                  onClick={() => props.logout()}
                >
                  <span className="cursor-pointer hover:opacity-50 text-sm">
                    Log out
                  </span>
                </div>
              </div>
            </div>
            <div className="py-5 lg:py-10 flex justify-center lg:justify-between">
              <Brand />
              <div className="desktop-menu hidden lg:block">
                <RenderMenu
                  {...props}
                  closeMobileMenu={() => {
                    setOpenMobileMenu(false)
                  }}
                />
              </div>
            </div>
            {/* DESKTOP */}
            <div className="hidden lg:flex absolute top-4 right-0">
              <div
                className="cursor-pointer opacity-50 hover:opacity-100 text-sm"
                onClick={() => props.logout()}
              >
                Log out
              </div>
            </div>
          </header>
        )}
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    router: state.router,
    role: state.Auth.role,
    token: state.Auth.access_token,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header))
