import React, { Suspense, lazy, useCallback } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { ConnectedRouter } from "connected-react-router"
// import { I18nProvider } from "@lingui/react"

// Redux
import { connect } from "react-redux"

// UI Component
import { Header } from "./components/layouts"
import { Container, Loader } from "./components/atoms"

// Helper
import { isUserLoginSuccessful } from "./utils/helper"

// Public Container
const Login = lazy(() => import("./screens/Public/Login"))
const ForgotPassword = lazy(() => import("./screens/Public/ForgotPassword"))
const ResetPassword = lazy(() => import("./screens/Public/ResetPassword"))

// Admin Container
const Groups = lazy(() => import("./screens/Groups/Groups"))
const GroupAddEdit = lazy(() => import("./screens/Groups/GroupAddEdit"))
const GroupDetail = lazy(() => import("./screens/Groups/GroupDetail"))
const MemberAddEdit = lazy(() => import("./screens/Groups/MemberAddEdit"))
const ConsoleUserAdd = lazy(() => import("./screens/Groups/ConsoleUserAdd"))
const GroupActivity = lazy(() => import("./screens/Groups/Activity"))

const Users = lazy(() => import("./screens/Users/Users"))
const UserAddEdit = lazy(() => import("./screens/Users/UserAddEdit"))
const UserActivity = lazy(() => import("./screens/Users/Activity"))

const Consoles = lazy(() => import("./screens/Consoles/Consoles"))
const ConsoleAddEdit = lazy(() => import("./screens/Consoles/ConsoleAddEdit"))

const Controllers = lazy(() => import("./screens/Controllers/Controllers"))
const ControllerAddEdit = lazy(() =>
  import("./screens/Controllers/ControllerAddEdit")
)

const AuditLogs = lazy(() => import("./screens/Audit/AuditLogs"))

const IceServers = lazy(() => import("./screens/VideoCall/IceServers"))
const IceServersAddEdit = lazy(() =>
  import("./screens/VideoCall/IceServersAddEdit")
)

const Account = lazy(() => import("./screens/Account/Account"))
const ChangePassword = lazy(() => import("./screens/Account/ChangePassword"))
const Setup2FA = lazy(() => import("./screens/Account/TwoFactorAuth/Setup2FA"))
const Disable2FA = lazy(() =>
  import("./screens/Account/TwoFactorAuth/Disable2FA")
)

const AdminSwitch = () => {
  return (
    <div className="py-4 pb-10">
      <Switch>
        <Redirect from="/" to="/groups" exact />

        <Route exact path="/groups" render={(props) => <Groups {...props} />} />
        <Route
          exact
          path="/groupaddedit/:group_code?"
          render={(props) => <GroupAddEdit {...props} />}
        />
        <Route
          exact
          path="/groupdetail/:group_code"
          render={(props) => <GroupDetail {...props} />}
        />
        <Route
          exact
          path="/memberaddedit/:group_code/:type?/:member_id?"
          render={(props) => <MemberAddEdit {...props} />}
        />
        <Route
          exact
          path="/consoleuseradd/:group_code"
          render={(props) => <ConsoleUserAdd {...props} />}
        />
        <Route
          exact
          path="/groupactivity/:group_code"
          render={(props) => <GroupActivity {...props} />}
        />
        <Route exact path="/users" render={(props) => <Users {...props} />} />
        <Route
          exact
          path="/useraddedit/:user_id?"
          render={(props) => <UserAddEdit {...props} />}
        />
        <Route
          exact
          path="/useractivity/:user_id"
          render={(props) => <UserActivity {...props} />}
        />
        <Route
          exact
          path="/devices"
          render={(props) => <Consoles {...props} />}
        />
        <Route
          exact
          path="/consoleaddedit"
          render={(props) => <ConsoleAddEdit {...props} />}
        />
        <Route
          exact
          path="/controllers"
          render={(props) => <Controllers {...props} />}
        />
        <Route
          exact
          path="/controlleraddedit/:type?"
          render={(props) => <ControllerAddEdit {...props} />}
        />
        <Route
          exact
          path="/auditlogs"
          render={(props) => <AuditLogs {...props} />}
        />
        <Route
          exact
          path="/videocall/iceservers"
          render={(props) => <IceServers {...props} />}
        />
        <Route
          exact
          path="/videocall/iceservers/addedit/:id?"
          render={(props) => <IceServersAddEdit {...props} />}
        />
        <Route
          exact
          path="/account"
          render={(props) => <Account {...props} />}
        />
        <Route
          exact
          path="/account/changepassword"
          render={(props) => <ChangePassword {...props} />}
        />
        <Route
          exact
          path="/account/setup2fa"
          render={(props) => <Setup2FA {...props} />}
        />
        <Route
          exact
          path="/account/disable2fa"
          render={(props) => <Disable2FA {...props} />}
        />
      </Switch>
    </div>
  )
}

const PublicSwitch = () => {
  return (
    <Switch>
      <Route exact path="/" render={(props) => <Login {...props} />} />
      <Route
        exact
        path="/forgotpassword"
        render={(props) => <ForgotPassword {...props} />}
      />
      <Route
        exact
        path="/resetpassword"
        render={(props) => <ResetPassword {...props} />}
      />
    </Switch>
  )
}

const AppRouter = (props) => {
  const {
    history,
    loader,
    token,
    role,
    is_2FA_enabled,
    is_2FA_login_success,
    login2FA,
  } = props

  const getRoleNavSwitch = (currentRole = null) => {
    switch (currentRole) {
      case "superadmin":
        return <AdminSwitch />
      default:
        break
    }
    return <PublicSwitch />
  }

  const RouteSwitch = useCallback(
    (attr) => {
      const currentRole = attr.role
      if (currentRole !== null) {
        if (is_2FA_enabled === true) {
          if (
            (login2FA.isSubmitted === true && login2FA.error === false) ||
            is_2FA_login_success === true
          ) {
            return getRoleNavSwitch(currentRole)
          }
        } else {
          return getRoleNavSwitch(currentRole)
        }
      }

      return getRoleNavSwitch()
    },
    [
      is_2FA_enabled,
      is_2FA_login_success,
      login2FA.isSubmitted,
      login2FA.error,
      role,
    ]
  )

  // start: check if user accessing protected routes when login fail
  const exceptionPaths = ["/", "/forgotpassword", "/resetpassword"]
  let location = window.location

  if (
    isUserLoginSuccessful() == false &&
    exceptionPaths.indexOf(location.pathname) < 0
  ) {
    history.push("/")
  } else {
    if (
      isUserLoginSuccessful() == false &&
      exceptionPaths.indexOf(location.pathname) < 0
    ) {
      history.push("/")
    }
  }

  // end

  return (
    <Suspense fallback={<Loader />}>
      <ConnectedRouter history={history}>
        <Container>
          {loader && <Loader />}
          <Header {...props} />
          <RouteSwitch role={role} />
        </Container>
      </ConnectedRouter>
    </Suspense>
  )
}

const mapStateToProps = (state) => {
  return {
    loader: state.App.loader,
    token: state.Auth.access_token,
    role: state.Auth.role,
    is_2FA_enabled: state.Auth.is_2FA_enabled,
    is_2FA_login_success: state.Auth.is_2FA_login_success,
    login2FA: state.Auth.login2FA,
  }
}

export default connect(mapStateToProps, null)(AppRouter)
