import _ from "lodash"
import { store } from "./../redux/store"

export const _findOption = (list, id) => {
  return list.find((option) => {
    return _.isObject(option.value)
      ? _objectIsEqual(option.value, id)
      : String(option.value) === String(id)
  })
}

export const _getNiceStringDate = (date) => {
  var month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  return (
    date.getDate() + " " + month[date.getMonth()] + " " + date.getFullYear()
  )
}

export const _getFormatAPIDate = (rawDate) => {
  const date = new Date(rawDate)

  if (date === undefined) {
    return ""
  }
  return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
}

export const _buildFormData = (formData, data, parentKey) => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof Blob)
  ) {
    Object.keys(data).forEach((key) => {
      _buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      )
    })
  } else {
    const value = data == null ? "" : data

    formData.append(parentKey, value)
  }
}

export const _makeId = (length) => {
  var result = ""
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const _difference = (object, base) => {
  return _.transform(object, (result, value, key) => {
    if (!_.isEqual(value, base[key])) {
      result[key] =
        _.isObject(value) && _.isObject(base[key])
          ? _.difference(value, base[key])
          : value
    }
  })
}

export const _objectIsEqual = (object, base) => {
  return _.isEmpty(_difference(object, base))
}

export const _combinePageAndSortParams = (page, sort) => {
  let params = ""

  if (page > 0) {
    params += "page=" + page
  }

  if (params !== "") {
    params += "&"
  }

  params += "sort=" + sort.column + ":" + sort.order

  return params
}

export const _capitalizeFirstCharacter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// export const _processFormError = (errors) => {
//   if (!_.isEmpty(errors)) {
//     return produce([], (draft) => {
//       errors.forEach((error, index) => {
//         let errorMessage = error.message
//         if (_.isArray(error.message)) {
//           errorMessage = error.message.join("<br>")
//         }
//         draft[error.index] = errorMessage
//       })
//     })
//   }
//   return []
// }

export const _matchRouteSlug = (stack, needle) => {
  const isMatched = stack.find((item) => {
    if (needle === "/") {
      // Direct match for the root path "/"
      return item === "/"
    }

    // Check for an exact match first
    if (needle === item) {
      return true
    }

    // Check if needle starts with item (handling nested routes)
    const isPartialMatch =
      needle.startsWith(item) && needle[item.length] === "/"

    // console.log("-------")
    // console.log("item", item)
    // console.log("needle", needle)
    // console.log("isPartialMatch", isPartialMatch)

    if (isPartialMatch) {
      return true
    }
  })
  // console.log("isMatched", isMatched)

  return isMatched !== undefined
}

export const _buildFieldOptions = (
  list,
  data = {
    labelKey: "",
    valueKey: "",
  },
  format = {
    autoCapitalize: false,
  }
) => {
  if (_.isEmpty(data) || data.labelKey === "" || data.valueKey === "") {
    return []
  }

  return list.map((item) => {
    let label = item[data.labelKey]
    let value = item[data.valueKey]

    if (format.autoCapitalize === true) {
      label = _capitalizeFirstCharacter(label)
    }

    return {
      label: label,
      value: value,
    }
  })
}

export const processErrorMessage = (errors) => {
  if (!_.isEmpty(errors)) {
    let draft = {}
    if (_.isObject(errors)) {
      draft = {
        ...errors,
      }
    } else {
      draft = {
        error: errors,
      }
    }
    return draft
  }
  return {}
}

export const isUserLoginSuccessful = () => {
  const { access_token, is_2FA_enabled, is_2FA_login_success, login2FA } =
    store.getState().Auth

  if (access_token === null) {
    return false
  } else {
    if (
      is_2FA_enabled === true &&
      login2FA.error !== false &&
      is_2FA_login_success !== true
    ) {
      return false
    }
  }

  return true
}
